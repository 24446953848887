// fonts
import "./src/styles/fonts.css"
// custom CSS styles
import "./src/styles/global.css"

// Simple React lightbox (used for galleries)
import React from 'react'
import SimpleReactLightbox from 'simple-react-lightbox'

// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => (
  <SimpleReactLightbox>{element}</SimpleReactLightbox>
)